import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Footer from '../Home/Footer'
import Content from './Content'
import Footer1 from '../Home/Footer1'
import Header1 from '../Home/Header1'

export default function Collections() {
  return (
    <>
     <Header1/>
     <Banner
      title='Collections' 
      description='Medical Devices'/>
      <Content/>
      <Footer1/>
    </>
  )
}
