import React from 'react'
import Header from './Header'
import Banner from './Banner'
import Deliver from './Deliver'
import About from './About'
import Prevention from './Prevention'
import Mask from './Mask'
import Basic from './Basic'
import Protection from './Protection'
import Supply from './Supply'
import Feature from './Feature'
import Arrivals from './Arrivals'
import Services from './Services'
import Why from './Why'
import Contact from './Contact'
import Footer from './Footer'
import Footer1 from './Footer1'
import Content from '../Collection/Content'
import Banner1 from './Banner1'
import Banner3 from './Banner3'
import Featured from './Featured'
import Testimonial from './Testimonial'
import Testimonials from '../ABout.jsx/Testimonials'
import BannerAbove from './BannerAbove'
import About1 from './About1'
import Product from './Product'

export default function HomePage() {
  return (
    <>
    <div className="w-[100vw] bg5 ">
      <BannerAbove/>
     <Header/> 
     {/* <Banner/> */}
     <Banner3/>
    </div>
    <About1/>
     {/* <Deliver/> */}
     {/* <Banner1/> */}
     <Product/>
     {/* <Featured/> */}
     <About/>
     {/* <Prevention/> */}
     <Mask/>
     <Basic/>
     {/* <Protection/> */}
     {/* <Supply/> */}
     {/* <Feature/> */}
     {/* <Arrivals/> */}
     <Testimonials/>
     {/* <Services/> */}
     <Why/>
     <Contact/>
     <Footer1/>
    </>
  )
}
