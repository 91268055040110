import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Details from './Details'
import Footer from '../Home/Footer'
import Footer1 from '../Home/Footer1'
import Header1 from '../Home/Header1'

export default function BlogsDetail() {
  return (
    <>
     <Header1/>
     <Banner
     title='Blog Title'  
     description="Antibodies Used For Children Health"     
     /> 
     <Details/>
     <Footer1/>
    </>
  )
}
