import { motion } from 'framer-motion';
import React, { useState } from 'react'
import { FaStar } from 'react-icons/fa'
import { IoCartOutline } from "react-icons/io5";
export default function List({data,index,grids}) {
    const [card, setCard] = useState(false);
  return (
    <div
  
      onMouseEnter={() => setCard(true)}
      onMouseLeave={() => setCard(false)}
    className={`w-[100%] bg-white relative rounded-xl py-4 px-4  flex flex-col  gap-3`}>
        <div className='absolute w-[2.5rem] h-[2.5rem] flex items-center rounded-full bottom-[1rem] right-[1rem] justify-center text-white bg-[#03a297]'>
        <IoCartOutline />
        </div>
        <motion.div
       animate={{
        scale: card ? 1.1 : 1,
        transition: { duration: 0.3 },
 
       }}
        className={`${!grids?'w-[100%]':'w-[20%]'} flex items-center justify-center `}>
            <img src={data.image} alt="" className='w-[100%]'/>

        </motion.div>
        <h1 className='text-xl font-bold text-[#252525]'>
        {data.name}
        </h1>
        <div className='flex items-center text-[#f7e929] text-md gap-1'>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
        </div>
        <h1 className='text-red-600 font-semibold'>{data.price}</h1>
      
    </div>
  )
}
