import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Blogs from './Blogs'
import Footer from '../Home/Footer'
import Footer1 from '../Home/Footer1'
import Header1 from '../Home/Header1'


export default function BlogContainer() {
  return (
    <>
     <Header1/>
     <Banner
     title="NEWS"
     description="Blogs"
     /> 
     <Blogs/>
     <Footer1/>
    </>
  )
}
