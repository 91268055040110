import React from 'react'
import { FaShoppingBag, FaStar } from 'react-icons/fa'

export default function Productss() {
  return (
    <div className='bg-slate-100 relative rounded-2xl p-3 flex gap-3 w-[100%]'>
                                    <div className='w-[3rem] h-[3rem] bg-[#1b878f] rounded-full border-[4px] text-white border-white flex items-center justify-center absolute right-0 bottom-0 '>
                                    <FaShoppingBag />
                                    </div>
                                    <div className='w-[40%] rounded-xl  overflow-hidden'>
                                        <img src={require('../../Assets/shop-13.webp')} alt="" />
                                    </div>
                                    <div className="w-[60%] flex flex-col gap-2">
                                        <h1 className='text-xl font-bold tracking-wider'>
                                        Surgical Face Mask</h1>
                                        <div className="flex gap-1 text-[#fffb21] text-xl">

                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                        </div>
                                        <div className='flex gap-2'>
                                            <h1 className='text-gray-600   text-lg font-semibold'>
                                            $60.00
                                            </h1>
                                            <h1 className='text-blue-500 underline-offset-1 text-lg font-bold'>
                                           $80.00
                                            </h1>

                                        </div>
                                    </div>

                                </div>
  )
}
